// GA
import ReactGA from "react-ga4";

// utils
import { lazy, Suspense } from "react";
import { changeI18nLanguage } from "@utils/helpers";

// styles
import ThemeStyles from "@styles/theme";
import "./style.scss";
import "react-toastify/dist/ReactToastify.min.css";
import "react-grid-layout/css/styles.css";

// contexts
import { SidebarProvider } from "@contexts/sidebarContext";
import { ThemeProvider } from "styled-components";
import { ModalProvider } from "@contexts/modalContext";

// hooks
import { useThemeProvider } from "@contexts/themeContext";
import { useEffect, useRef } from "react";
import { useWindowSize } from "react-use";

// components
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Loader from "@components/Loader";
import Sidebar from "@components/Sidebar";
import BottomNav from "@components/BottomNav";
import { Provider } from "react-redux";
import store from "@store";
// ** Config Imports
import "@configs/i18n";

const Dashboard = lazy(() => import("@pages/Dashboard"));
const Deposit = lazy(() => import("@pages/Deposit"));
const Withdraw = lazy(() => import("@pages/Withdraw"));
const History = lazy(() => import("@pages/History"));
const Staking = lazy(() => import("@pages/Staking"));
const Swap = lazy(() => import("@pages/Swap"));
const Trade = lazy(() => import("@pages/Trade"));
const Actions = lazy(() => import("@pages/Actions"));
const Wallet = lazy(() => import("@pages/Wallet"));
const NFT = lazy(() => import("@pages/NFT"));
const Collections = lazy(() => import("@pages/Collections"));
const SignIn = lazy(() => import("@pages/SignIn"));
const SignUp = lazy(() => import("@pages/SignUp"));
const Profile = lazy(() => import("@pages/Profile"));
const FindPassword = lazy(() => import("@pages/FindPassword"));
const Verification = lazy(() => import("@pages/Verification"));
const CompletePasswordReset = lazy(() =>
  import("@pages/Complete/PasswordReset")
);
const CompleteSignUp = lazy(() => import("@pages/Complete/SignUp"));

const App = () => {
  const appRef = useRef(null);
  const { theme } = useThemeProvider();
  const { width } = useWindowSize();

  useEffect(() => {
    appRef.current && appRef.current.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      changeI18nLanguage(storedLanguage);
    } else {
      changeI18nLanguage("en");
    }
  }, []);

  const gaKey = process.env.REACT_APP_PUBLIC_GOOGLE_ANALYTICS;
  gaKey && ReactGA.initialize(gaKey);

  return (
    <Provider store={store}>
      <SidebarProvider>
        <ThemeProvider theme={{ theme: theme }}>
          <ThemeStyles />
          <ToastContainer
            theme="colored"
            autoClose={2000}
            toastStyle={{ borderRadius: 4 }}
          />
          <ModalProvider>
            <div ref={appRef}>
              {width < 768 ? <BottomNav /> : <Sidebar />}
              <Suspense fallback={<Loader visible />}>
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/deposit" element={<Deposit />} />
                  <Route path="/withdraw" element={<Withdraw />} />
                  <Route path="/history" element={<History />} />
                  <Route path="/swap" element={<Swap />} />
                  <Route path="/staking" element={<Staking />} />
                  <Route path="/trade" element={<Trade />} />
                  <Route path="/actions" element={<Actions />} />
                  <Route path="/wallet" element={<Wallet />} />
                  <Route path="/nft" element={<NFT />} />
                  <Route path="/collections" element={<Collections />} />
                  <Route path="/sign-in" element={<SignIn />} />

                  <Route path="/sign-up" element={<SignUp />} />

                  <Route path="/profile" element={<Profile />} />
                  <Route path="/find-password" element={<FindPassword />} />
                  <Route path="/verification" element={<Verification />} />

                  <Route
                    path="/complete-password-reset"
                    element={<CompletePasswordReset />}
                  />
                  <Route
                    path="/complete-sign-up"
                    element={<CompleteSignUp />}
                  />
                </Routes>
              </Suspense>
            </div>
          </ModalProvider>
        </ThemeProvider>
      </SidebarProvider>
    </Provider>
  );
};

export default App;
