import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from "./auth";
import { walletSlice } from "./wallet";
import { historySlice } from "./history";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    wallet: walletSlice.reducer,
    history: historySlice.reducer,
  },
});

export default store;
