import { createSlice } from "@reduxjs/toolkit";

export const walletSlice = createSlice({
  name: "wallet",
  initialState: {
    walletAddress: null,
    ethBalance: 0,
    maticBalance: 0,
    totalMaticDoller: 0,
    tokenBalance: 0,
    totalDoller: 0,
    totalEthDoller: 0,
    totalCodaDoller: 0,
    totalUSDTDoller: 0,
    usdtBalance: 0,
    sendOuterBalance: 0,
  },
  reducers: {
    setWalletAddress: (state, action) => {
      state.walletAddress = action.payload;
    },
    setEthBalance: (state, action) => {
      state.ethBalance = action.payload;
    },
    setTokenBalance: (state, action) => {
      state.tokenBalance = action.payload;
    },
    setTotalDoller: (state, action) => {
      state.totalDoller = action.payload;
    },
    setTotalEthDoller: (state, action) => {
      state.totalEthDoller = action.payload;
    },
    setTotalCodaDoller: (state, action) => {
      state.totalCodaDoller = action.payload;
    },
    setTotalUSDTDoller: (state, action) => {
      state.totalUSDTDoller = action.payload;
    },
    setUSDTBalance: (state, action) => {
      state.usdtBalance = action.payload;
    },
    setSendOuterBalance: (state, action) => {
      state.sendOuterBalance = action.payload;
    },
    setMaticBalance: (state, action) => {
      state.maticBalance = action.payload;
    },
    setTotalMaticDoller: (state, action) => {
      state.totalMaticDoller = action.payload;
    },
  },
});

export const {
  setWalletAddress,
  setEthBalance,
  setTokenBalance,
  setTotalDoller,
  setTotalEthDoller,
  setTotalCodaDoller,
  setTotalUSDTDoller,
  setUSDTBalance,
  setSendOuterBalance,
  setMaticBalance,
  setTotalMaticDoller,
} = walletSlice.actions;
