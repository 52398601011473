import { createSlice } from "@reduxjs/toolkit";

export const historySlice = createSlice({
  name: "history",
  initialState: {
    ethHistory: [],
    erc20History: [],
    usdtHistory: [],
    swapHistory: [],
  },
  reducers: {
    setEthHistory: (state, action) => {
      state.ethHistory = action.payload;
    },
    setErc20History: (state, action) => {
      state.erc20History = action.payload;
    },
    setUsdtHistory: (state, action) => {
      state.usdtHistory = action.payload;
    },
    setSwapHistory: (state, action) => {
      state.swapHistory = action.payload;
    },
  },
});

export const {
  setEthHistory,
  setErc20History,
  setUsdtHistory,
  setSwapHistory,
} = historySlice.actions;
